import $ from 'jquery'
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import formatISO from 'date-fns/formatISO';
import { ExpenseTable } from "../../components/expenseTable";
import { NewExpenseFormButton } from "../../components/newExpenseFormButton";
import { useAppDispatch } from "../../hooks/reduxToolkit";
import { IncidentModel, deleteIncident, updateIncident } from "../../reduxToolkit/incidentsSlice";
import { EditExpenseOnUpdatedFn } from "../../components/forms/editExpenseRowForm";
import { SubmissionHistory } from "../../components/submissionHistory";
import { RequireIncidentProvided, requireIncident } from "../../wrappers/requireIncident";
import { EditableDate } from "../../components/editable/editableDate";
import { IncidentSubmitButton } from "../../components/incidentSubmitButton";
import { ExpenseModel, deleteExpense, isCompleteExpense } from "../../reduxToolkit/expensesSlice";
import { present } from "../../../lib/util/present";
import { Tooltip } from "../../components/tooltip";
import { EditableText } from "../../components/editable/editableText";
import { IncidentSelect } from '../../components/formComponents/incidentSelect';
import { chooseSubmissionType } from '../../../lib/rules/chooseSubmissionType';
import { formatDateInTimeZone } from '../../../lib/formatDateInTimeZone';
import { useCustomization } from '../../hooks/useCustomizations';


import './show.scss'

interface IncidentShowProps extends RequireIncidentProvided {
}

function IncidentsShow({ incident }: IncidentShowProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  

  const [highlightExpenseId, setHighlightExpenseId] = useState<string | null>(location.state?.expense_id || null)
  const [editingIncidentDate, setEditingIncidentDate] = useState<true | undefined>(undefined)
  const [editingIncidentTitle, setEditingIncidentTitle] = useState<true | undefined>(undefined)

  const update = useCallback((value: Partial<IncidentModel>) => {
    dispatch(updateIncident({
      id: incident.id,
      ...value,
      updated_at: formatISO(new Date())
    }))
  }, [dispatch, incident.id])

  const onExpenseUpdated = useCallback<EditExpenseOnUpdatedFn>((expense) => {
    setHighlightExpenseId(expense.id)
    if (expense.incident_id && expense.incident_id !== incident.id) {
      navigate(`/incidents/${expense.incident_id}`, { state: { expense_id: expense.id } })
    } else if (!expense.incident_id) {
      navigate('/expenses', { state: { expense_id: expense.id } })
    }
  }, [navigate, incident.id])

  const [showAllExpenses, setShowAllExpenses] = useState(false)
  const pendingExpenses = incident.expenses.filter((e) => !present(e.submitted_at))

  let headerText = <h4>Your Unsubmitted Expenses</h4>
  if (showAllExpenses) {
    headerText = <h4>All Your Expenses</h4>
  } else if (pendingExpenses.length === 0) {
    headerText = <i>All Expenses Submitted</i>
  }

  let expensestoShow: ExpenseModel[]
  if (showAllExpenses) {
    expensestoShow = incident.expenses
  } else {
    expensestoShow = pendingExpenses
  }

  const isEditing = editingIncidentDate || editingIncidentTitle
  
  const isDemoIncident = incident.description === 'Broken Leg (demo incident)'
  const isDisabled = isDemoIncident // In the future, we may have more reasons to disable editing the incident.
  const disabledTooltipText = isDemoIncident ?
    'This is a demo incident, it will be deleted in an hour.' :
    null

  return <div className="incidents-show mt-4">
    <div className="row incidents-show__header-row">
      <div className="col-12 incidents-show__header">
        <h1>
          <Tooltip tooltip={disabledTooltipText}>
          <EditableText
            editing={editingIncidentTitle}
            value={incident.description}
            disabled={isDisabled}
            onChange={(description) => {
              update({description})
              setEditingIncidentTitle(undefined)
            }} />
          </Tooltip>
        </h1>

        <div className="incidents-show__header__actions">
          
          <Tooltip tooltip={disabledTooltipText}>
            <button className={`btn btn-sm btn-outline-info me-2 ${isDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (isEditing) {
                    setEditingIncidentDate(undefined)
                    setEditingIncidentTitle(undefined)
                  } else if(!isDisabled) {
                    setEditingIncidentDate(true)
                    setEditingIncidentTitle(true)
                  }
                }}>
              {isEditing ? 'Done' : 'Edit'}
            </button>
          </Tooltip>
          <IncidentDeleteButton incident={incident} />
        </div>
      </div>

      <div className='col-12 col-lg-6 incidents-show__info'>
        <p className=''>
          <strong>Patient:</strong> <br />
          {incident.patient_name}
        </p>
        <p className=''>
          <strong>Start Date:</strong> <br />
          <EditableDate value={incident.start_date}
              format="MMM d, yyyy"
              editing={editingIncidentDate}
              disabled={isDisabled}
              onChange={(start_date) => {
                update({start_date})
                setEditingIncidentDate(undefined)
              }} />
        </p>

        <div>
          <label className='form-label me-auto'><strong>Change Incident</strong></label>
          <IncidentSelect
            id={`incident-show/${incident.id || ''}`}
            className="form-select"
            value={incident.id}
            blankOption="search all expenses"
            denyNewIncidents
            onSelect={(value) => {
              if (!incident.id && !value) return
              if (incident.id && !value) return navigate(`/expenses`)

              if (value && 'id' in value && value.id !== incident.id) {
                navigate(`/incidents/${value.id}`)
              }
            }}
            />
        </div>
      </div>

      <div className='col-12 col-lg-6 incidents-show__badges'>
        <IncidentTypeBadge incident={incident} />
      </div>
    </div>
    <div className="row">
      <div className="col-12 incidents-show__documents">
        <IncidentSubmitButton key={`incident/${incident.id}/submit-btn`} incident={incident} />
      </div>

      <hr className='hr-thick' />

      <div className="col-12 incidents-show__expenses">
        <div className="incidents-show__expenses-header">
          <div>
            {headerText}

            <button className="btn btn-link" onClick={() => setShowAllExpenses(!showAllExpenses)}>
              {showAllExpenses ?
                'Show Unsubmitted Expenses Only' :
                'Show All Expenses'}
            </button>
          </div>

          <div className="incidents-show__expenses-add">
            <NewExpenseFormButton
              incidentId={incident.id}
              allowNewIncident={false}
              disabled={isDemoIncident}
              tooltip={disabledTooltipText}
              prefilledData={{
                patient_name: incident.patient_name,
                patient_dob: incident.patient_dob,
                incident_id: incident.id
              }}
              disabledFields={[
                'patient_name',
                'patient_dob'
              ]}
              onInserted={!isDemoIncident ? onExpenseUpdated : undefined} />
            <Link to={`/incidents/${incident.id}/bulkAddExpense`}
                className="btn btn-link incidents-show__expenses-add__bulk">
              add multiple expenses
            </Link>
          </div>
        </div>

        <ExpenseTable expenses={expensestoShow}
          displayOptions={{ hideIncident: true, highlightExpenseId }}
          onExpenseUpdated={!isDemoIncident ? onExpenseUpdated : undefined} />
      </div>

      {(incident.is_addon_incident || incident.submissions.length > 0) && <>
        <div className="col-12 incidents-show__submissions mt-4">
          <h4>Previous Submissions</h4>
          <SubmissionHistory submissions={incident.submissions} />
          {incident.is_addon_incident &&
            <div className='submission-history__row col-12 card'>
              <div className="card-body">
                <div className={`submission-history__row-header`}>
                  <div className="card-title">
                    <Tooltip tooltip='You checked that you had previously submitted some expenses to CHM for this incident, but did not track them here.'>
                      <i>Previously Submitted to CHM before {formatDateInTimeZone(incident.created_at, { format: 'MMM dd' })}</i>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>}
        </div>
        </>}
    </div>
  </div>
}

export default requireIncident(IncidentsShow)

function IncidentDeleteButton({ incident }: RequireIncidentProvided) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (present(incident.submitted_at)) {
    // Can't delete an incident that was submitted in the past
    return null
  }

  const completeExpenses = incident.expenses.filter(isCompleteExpense)

  if (completeExpenses.length > 0) {
    return <Tooltip
        tooltip="Delete your pending expenses or move them to another incident first">
      <button className="btn btn-sm btn-outline-secondary disabled">Delete</button>
    </Tooltip>
  }

  // Either 0 expenses or only incomplete expenses.
  return <button className="btn btn-sm btn-outline-danger"
      onClick={() => {
        if (incident.expenses.length > 0 &&
            !window.confirm(`Delete this incident and its ${incident.expenses.length} expenses?`)) {
          return
        }

        const now = new Date().toISOString()
        incident.expenses.forEach((expense) => {
          dispatch(deleteExpense({
            id: expense.id,
            updated_at: now,
            deleted_at: now
          }))
        })
        dispatch(deleteIncident({
          id: incident.id,
          updated_at: now,
          deleted_at: now
        }))

        navigate('/expenses')
      }}>
    Delete
  </button>
}

function IncidentTypeBadge({ incident }: RequireIncidentProvided) {
  const hra = useCustomization('hra')
  const submissionType = chooseSubmissionType(
    {
      incident,
      incidentExpenses: incident.expenses,
      incidentSubmissions: incident.submissions
    },
    {hra}
  )

  switch(submissionType) {
    case 'CHM':
      return <>
        <div className="d-none d-md-block">
          <p>This incident qualifies for reimbursement from CHM.</p>
        </div>
        <Tooltip tooltip="This incident qualifies for reimbursement from CHM.">
          <div className='badge bg-info d-md-none'>
            CHM
          </div>
        </Tooltip>
      </>
    case 'CHM-addon':
      return <>
      <div className="d-none d-md-block">
        <p>This incident was previously submitted to CHM, so all new expenses will be add-on expenses.</p>
      </div>
      <Tooltip tooltip={"This incident was previously submitted to CHM, so all new expenses will be add-on expenses."}>
        <div className='badge bg-secondary d-md-none'>
          CHM Add-On
        </div>
      </Tooltip>
      </>
    case 'HRA':
      return <>
      <div className="d-none d-md-block">
        <p>This incident does not qualify for reimbursement from CHM.  It may be eligible for reimbursement through your HRA.</p>
      </div>
      <Tooltip tooltip="This incident does not qualify for reimbursement from CHM.  It may be eligible for reimbursement through your HRA.">
        <div className='badge bg-secondary d-md-none'>
          Not CHM Eligible
        </div>
      </Tooltip>
    </>
    case null:
      if (incident.expenses.length === 0) {
        return <>
          <div className="d-none d-md-block">
            <p>
              You have not added any expenses to this incident yet.
            </p>
          </div>
          <Tooltip tooltip="You have not added any expenses to this incident yet.">
            <div className='badge bg-secondary d-md-none'>
              No Expenses
            </div>
          </Tooltip>
        </>
      }
    
      if (incident.expenses.some((e) => !isCompleteExpense(e))) {
        return <>
          <div className="d-none d-md-block">
            <p>
              Some of your expenses are missing data.  Please complete them before submitting.
            </p>
          </div>
          <Tooltip tooltip="Some of your expenses are missing data.  Please complete them before submitting.">
            <div className='badge bg-secondary d-md-none'>
              Missing Data
            </div>
          </Tooltip>
        </>
      }

      let tooltip = 'This incident does not qualify for reimbursement.'
      if (hra?.providesHraCard) {
        tooltip += '  If all your expenses were paid with the HRA card, there is no need to submit for reimbursement.'
      }
      return <>
      <div className="d-none d-md-block">
        <p>
          This incident does not qualify for reimbursement.<br/>
          {hra?.providesHraCard && 'If all your expenses were paid with the HRA card, there is no need to submit for reimbursement.'}
        </p>
      </div>
      <Tooltip tooltip={tooltip}>
        <div className='badge bg-secondary d-md-none'>
          No Reimbursement
        </div>
      </Tooltip>
    </>
    default:
      return null
  }
}
