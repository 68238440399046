import { present } from "async-toolbox"
import { ExpenseModel, isSubmittedExpense, isCompleteExpense, isCompleteExceptForIncidentID } from "../../app/reduxToolkit/expensesSlice"
import { RootState } from "../../app/reduxToolkit/store"
import { VirtualToDo } from "./types"
import { formatDateInTimeZone } from "../formatDateInTimeZone"
import { calculateDueDate, chooseSubmissionType } from "../rules/chooseSubmissionType"
import { formatCurrency } from "../formatCurrency"
import { selectCustomizations } from "../../app/reduxToolkit/selectors/customizations"

export function * createToDosForExpense(s: RootState, expense: ExpenseModel): Generator<VirtualToDo> {
  // never any TODOs for an already submitted expense
  if (isSubmittedExpense(expense)) { return }

  if (!isCompleteExpense(expense)) {
    const dueDate = expense.date ? calculateDueDate(expense.date) : undefined

    yield {
      key: `expense/${expense.id}/fill`,
      title: isCompleteExceptForIncidentID(expense) ?
        `Assign this expense to an incident: ${formatExpenseInline(expense)}` :
        `Add missing data for this expense: ${formatExpenseInline(expense)}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'fill',
      dueDate,
      action: {
        type: 'editExpense',
        parameters: {
          expenseId: expense.id,
          validateOnMount: true
        }
      }
    }
  }

  const incident = s.incidents.incidents?.find((i) => i.id === expense.incident_id)
  if (!incident) { return }

  const customizations = selectCustomizations(s)
  const submissionType = chooseSubmissionType(
    {
      incident,
      incidentExpenses: s.expenses.expenses.filter((e) => e.incident_id === expense.incident_id),
      incidentSubmissions: s.submissions.submissions.filter((s) => s.incident_id === expense.incident_id),
    }, 
    customizations
  )
  
  // itemized-bill: needed if
  //  1. expense is not yet submitted
  //  2. no attachments yet
  //  3. incident is going to CHM or CHM-Addon
  if (
    (submissionType === 'CHM' || submissionType === 'CHM-addon') &&
    !present(s.attachments.attachments?.find((a) => a.record_id === expense.id))
  ) {
    yield {
      key: `expense/${expense.id}/itemized-bill`,
      title: `Get an Itemized Bill from ${expense.provider}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'itemized-bill',
      action: {
        type: 'attachReceipt',
        parameters: {
          expenseId: expense.id,
        }
      }
    }
  }

  // receipt: needed if
  //  1. expense is not yet submitted
  //  2. no attachments yet
  //  3. incident is going to HRA and this expense was paid from personal funds
  if (
    submissionType === 'HRA' && !expense.paid_with_hra &&
    !present(s.attachments.attachments?.find((a) => a.record_id === expense.id))
  ) {
    yield {
      key: `expense/${expense.id}/receipt`,
      title: `Attach a receipt from ${expense.provider}`,
      record_id: expense.id,
      record_type: 'expense',
      todo_type: 'receipt',
      action: {
        type: 'attachReceipt',
        parameters: {
          expenseId: expense.id,
        }
      }
    }
  }
}

function formatExpenseInline(expense: ExpenseModel): string {
  let { date, provider, paidAmount } = expense
  if (present(provider)) {
    provider = `at "${provider}"`
  }
  if (present(date)) {
    date = `on ${formatDateInTimeZone(date, { format: 'MMM d' })}`
  }
  if (present(paidAmount)) {
    paidAmount = `${formatCurrency(paidAmount)}`
  } else {
    paidAmount = 'unknown'
  }
  return [paidAmount, date, provider].filter(present).join(' ')
}
